
/*
toDo: 
- Error class: BEi FEhler soll die SChrift rot erscheinen
- Error und Succes nachrichten in eine Externe Datei auslagern, damit hier UND bei erfahrung verfassen nich so lange Texte stehen
*/

import React from 'react';
import Layout from '../../components/Layout/Layout';
import axiosInstance from '../../components/Axios';
import Spinner from '../../components/UI/Spinner/Spinner';
import Seo from '../../components/SEO/SEO';

class Bestaetigung extends React.Component {
  state = { 
    responseMessage: null,
    sendingRequest: false,
  };

  handleResponse = (responseData) => {
    // alert(typeof responseData);
    if(typeof responseData === 'string') { // string means some php error occured
      const htmlString = `<p>Dein Eintrag konnte leider auf Grund eines Fehlers nicht bestätigt werden:</p>
        <p>${responseData}</p>
        <p>Wende dich bitte an uns über den Menüpunkt Kontakt oder schreibe an support@tiny-house-helden.de!</p>`;
      this.setState({ 
        // dataSendSuccess: true ,
        error: true,
        sendingRequest: false,
        responseMessage:  <span dangerouslySetInnerHTML={{ __html: htmlString }} />
      });
      return null;
    }

    this.setState({ 
      // dataSendSuccess: true ,
      error: responseData.error,
      sendingRequest: false,
      responseMessage: responseData.message
    });
    
  }

  sendGetRequest = () => {
    this.setState({ sendingRequest: true });

    axiosInstance.get('php/validate.php' + this.props.location.search)
        .then((response) => {
          this.handleResponse(response.data);
        })
        .catch(error => {
          this.setState({
            error: true,
            responseMessage: `Folgender Fehler ist aufgetreten: "${error.message}". 
              Bitte wenden Sie sich an support@tiny-house-helden.de`,
            sendingData: false
          })
          console.dir(error);
        })
        .finally(() => {
        });
  };

  componentDidMount() {
    this.sendGetRequest();
    // void this.props;
    // debugger;
  }
  render() {
   
    return( 
      <>
       <Layout headlines={["Bestätigung"]} className="centeredPage--TextPage smallContent">
          <h1>Dein Erfahrungsbericht</h1>
          {!this.state.responseMessage && <Spinner centered />}
          {this.state.responseMessage || 'Bitte kurz warten...'}
        </Layout>
      </>
    );
  }
}

export default Bestaetigung;

export function Head() {
  return (
    <Seo
      noIndex
      title = 'Bestätige deinen Erfahrungsbericht | Tiny House Helden'
      meta_description =
        {''}
    />
  )
}